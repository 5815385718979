import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import BackgroundImage from "gatsby-background-image";
import Img from "gatsby-image";

import Layout from "../components/layout";
import SEO from "../components/seo";

const Blog = () => {
  const data = useStaticQuery(graphql`
    query {
      hero: imageSharp(fluid: { originalName: { eq: "blog-pattern.jpg" } }) {
        fluid(quality: 90, maxWidth: 1920) {
          presentationWidth
          presentationHeight
          ...GatsbyImageSharpFluid_withWebp
        }
      }
      list: allMarkdownRemark(
        sort: { fields: [frontmatter___date], order: DESC }
      ) {
        nodes {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMM DD, YYYY")
            title
            author
            excerpt
            image {
              childImageSharp {
                fluid(quality: 90, maxWidth: 360, maxHeight: 200) {
                  presentationWidth
                  presentationHeight
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    }
  `);

  const posts = data.list.nodes;

  return (
    <Layout dark={true} absolute={false}>
      <SEO
        title="Blog"
        pathname="/blog/"
        image={data.hero.fluid}
        keywords={[
          "singapore",
          "hire",
          "tech",
          "foreign",
          "talent",
          "offshore",
          "outsource",
          "programmer",
          "software",
          "developer",
          "blog",
          "articles"
        ]}
      />
      <BackgroundImage
        className="bg-cover bg-center bg-no-repeat page-title py-5 mb-5"
        fluid={data.hero.fluid}
      >
        <span className="bg-overlay" style={{ opacity: 0.02 }}></span>
        <div className="container bg-content py-md-5 text-center px-5">
          <h1 className="page-title-heading">Blog</h1>
        </div>
      </BackgroundImage>
      <div className="container blog-grid pb-5 mb-3">
        {posts.map(p => (
          <div className="blog-grid-item" key={p.fields.slug}>
            <div className="card blog-card mb-2">
              <a className="post-thumb" href={`/blog${p.fields.slug}`}>
                <Img
                  fluid={p.frontmatter.image.childImageSharp.fluid}
                  alt={p.frontmatter.tittle}
                  title={p.frontmatter.tittle}
                />
              </a>
              <div className="card-body">
                <h5 className="post-title">
                  <a href={`/blog${p.fields.slug}`}>{p.frontmatter.title}</a>
                </h5>
                <p className="text-mute">
                  {p.frontmatter.excerpt || p.excerpt}
                </p>
              </div>
              <div className="card-footer">
                <div className="post-author">{p.frontmatter.author}</div>
                <div className="post-meta">
                  <span>{p.frontmatter.date}</span>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </Layout>
  );
};

export default Blog;
